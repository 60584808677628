import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { getHotelReservations } from "../services/api"
import { LogOut, MapPin } from "lucide-react"
import Loading from "../components/Loading"
import BottomNavigation from "../components/BottomNavigation"
import hotelImg from "../assets/hotel-checkIn-img.jpg"
import bodySpaImg from "../assets/body-spa-img.jpg"
import breakfastImg from "../assets/breakfast-img.jpg"
import earlyCheckinImg from "../assets/early-checkin.jpg"
import lateCheckoutImg from "../assets/late-checkout.jpg"
import cabImg from "../assets/cab-img.jpg"
import luxuryImg from "../assets/luxury-room-img.jpg"
import "../styles/GuestDashboard.css"

// Import flag images
import ukFlag from "../assets/flags/uk.svg"
import esFlag from "../assets/flags/es.svg"
import frFlag from "../assets/flags/fr.svg"
import itFlag from "../assets/flags/it.svg"
import ptFlag from "../assets/flags/pt.svg"

// Language flags mapping
const languageFlags = {
  en: ukFlag,
  es: esFlag,
  fr: frFlag,
  it: itFlag,
  pt: ptFlag,
}

function GuestDashboard() {
  const [reservationData, setReservationData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isUpsell, setIsUpsell] = useState(true)
  const [showToast, setShowToast] = useState(false)
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false)
  const [propertyId, setPropertyId] = useState(null)
  const [reservationId, setReservationId] = useState(null)

  useEffect(() => {
    fetchReservationData()
  }, [])

  useEffect(() => {
    console.log("reservationData updated:", reservationData)
  }, [reservationData])

  const fetchReservationData = async () => {
    try {
      setIsLoading(true)
      const data = await getHotelReservations()
      console.log("Fetched reservation data:", JSON.stringify(data, null, 2))
      setReservationData(data)

      if (data.success && data.data && data.data.length > 0) {
        const propertyId = data.data[0].hotelDetails.propertyId
        const reservationId = data.data[0].bookingDetails.reservationId
        setPropertyId(propertyId)
        setReservationId(reservationId)
        setIsUpsell(data.data[0].hotelDetails.isUpsell)
      }
    } catch (error) {
      console.error("Error fetching reservation data:", error)
      alert("Error fetching reservation data. Please try again later.")
    } finally {
      setIsLoading(false)
    }
  }

  const handleLogout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("responseData")
    localStorage.removeItem("unlockingDetails")
    navigate("/")
  }

  const handlePreCheckIn = () => {
    if (reservationData && reservationData.data && reservationData.data.length > 0) {
      const isPreCheckin = reservationData.data[0].isPreCheckin
      const reservationId = reservationData.data[0].bookingDetails._id

      if (isPreCheckin) {
        navigate(`/bookingdetails/${reservationId}`)
      } else {
        navigate(`/pre-check-in/${reservationId}`)
      }
    } else {
      console.error("No reservation data available")
    }
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    setShowLanguageDropdown(false)
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="gd-app-container">
      <Header
        onLogout={handleLogout}
        reservationData={reservationData}
        onLanguageIconClick={() => setShowLanguageDropdown(!showLanguageDropdown)}
        showLanguageDropdown={showLanguageDropdown}
        changeLanguage={changeLanguage}
        currentLanguage={i18n.language}
      />
      <WelcomeCard reservationData={reservationData} onProceedCheckIn={handlePreCheckIn} />
      <div className="gd-main-content">
        <Picks
          reservationData={reservationData}
          propertyId={propertyId}
          reservationId={reservationId}
          isUpsell={isUpsell}
          setShowToast={setShowToast}
        />
      </div>
      {showToast && <div className="toast-message">{t("serviceNotAvailable")}</div>}
      <BottomNavigation />
    </div>
  )
}

const Header = ({
  onLogout,
  reservationData,
  onLanguageIconClick,
  showLanguageDropdown,
  changeLanguage,
  currentLanguage,
}) => {
  const { t } = useTranslation()
  const propertyLocation = reservationData?.data?.[0]?.hotelDetails?.propertyLocation
  const locationString = propertyLocation
    ? `${propertyLocation.city}, ${propertyLocation.country}`
    : t("locationNotAvailable")

  return (
    <div className="gd-dashboard-header">
      <div className="gd-profile-image" onClick={onLogout}>
        <LogOut size={24} />
      </div>
      <div className="gd-location-icon">
        <MapPin size={24} />
        <span>{locationString}</span>
      </div>
      <div className="gd-language-selector">
        <button onClick={onLanguageIconClick} className="gd-language-button">
          <img
            src={languageFlags[currentLanguage] || languageFlags["en"]}
            alt={`${currentLanguage} flag`}
            className="gd-language-flag"
          />
        </button>
        {showLanguageDropdown && (
          <div className="gd-language-dropdown">
            <button onClick={() => changeLanguage("en")}>
              <img src={languageFlags.en || "/placeholder.svg"} alt="UK flag" className="gd-language-flag-small" />
              <span>EN</span>
            </button>
            <button onClick={() => changeLanguage("es")}>
              <img src={languageFlags.es || "/placeholder.svg"} alt="Spain flag" className="gd-language-flag-small" />
              <span>ES</span>
            </button>
            <button onClick={() => changeLanguage("fr")}>
              <img src={languageFlags.fr || "/placeholder.svg"} alt="France flag" className="gd-language-flag-small" />
              <span>FR</span>
            </button>
            <button onClick={() => changeLanguage("it")}>
              <img src={languageFlags.it || "/placeholder.svg"} alt="Italy flag" className="gd-language-flag-small" />
              <span>IT</span>
            </button>
            <button onClick={() => changeLanguage("pt")}>
              <img
                src={languageFlags.pt || "/placeholder.svg"}
                alt="Portugal flag"
                className="gd-language-flag-small"
              />
              <span>PT</span>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

const WelcomeCard = ({ reservationData, onProceedCheckIn }) => {
  const { t } = useTranslation()
  const guestName = reservationData?.data?.[0]?.guestDetails?.personName?.givenName || t("guest")
  const propertyName = reservationData?.data?.[0]?.hotelDetails?.propertyName || t("hotel")

  const propertyImage = (() => {
    const imageData = reservationData?.data?.[0]?.hotelDetails?.propertyImage
    if (typeof imageData === "string") {
      return imageData
    } else if (Array.isArray(imageData) && imageData.length > 0) {
      return imageData[0]?.image
    }
    return hotelImg
  })()

  const isPreCheckin = reservationData?.data?.[0]?.isPreCheckin || false

  return (
    <div className="gd-welcome-card" style={{ backgroundImage: `url(${propertyImage})` }}>
      <div className="gd-welcome-content">
        <h2>{t("welcome", { name: guestName })}</h2>
        <div className="gd-checkin-btn">
          <p>{t("waitingToWelcome")}</p>
          <button className="gd-checkin-button" onClick={onProceedCheckIn}>
            {isPreCheckin ? t("goToBookingDetails") : t("proceedToPreCheckIn")}
          </button>
        </div>
        <p className="gd-account-name">{propertyName}</p>
      </div>
    </div>
  )
}

const Picks = ({ reservationData, propertyId, reservationId, isUpsell, setShowToast }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handlePickClick = (category) => {
    if (isUpsell) {
      const url = `/upsell/${propertyId}/${category}/${reservationId}`
      console.log(`Navigating to: ${url}`)
      navigate(url)
    } else {
      setShowToast(true)
      setTimeout(() => {
        setShowToast(false)
      }, 3000)
    }
  }

  const pickOptions = [
    {
      category: "WieldyEarlyCheckIn",
      image: earlyCheckinImg,
      label: "Early Check-in",
      translationKey: "earlyCheckin",
    },
    {
      category: "WieldyLateCheckout",
      image: lateCheckoutImg,
      label: "Late Check-out",
      translationKey: "lateCheckout",
    },
    {
      category: "WieldyRoomUpgrade",
      image: luxuryImg,
      label: "Room Upgrades",
      translationKey: "roomUpgrades",
    },
    {
      category: "WieldyBreakfast",
      image: breakfastImg,
      label: "Order Breakfast",
      translationKey: "orderBreakfast",
    },
    {
      category: "WieldySpa",
      image: bodySpaImg,
      label: "Body Spa",
      translationKey: "bodySpa",
    },
    {
      category: "WieldyTransportation",
      image: cabImg,
      label: "Book a Cab",
      translationKey: "bookCab",
    },
  ]

  return (
    <div className="gd-picks">
      <h3>Top Picks for You</h3>
      <div className="gd-pick-options">
        {pickOptions.map(({ category, image, label, translationKey }) => (
          <div key={category} className="gd-pick-option" onClick={() => handlePickClick(category)}>
            <img src={image || "/placeholder.svg"} alt={label} />
            <div className="gd-overlay">
              <p>{t(translationKey, label)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default GuestDashboard

